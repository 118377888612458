export const colors = {
  brand: '#007ec1',
  brandGreen: '#95bc1a',
}

export const articleColors = {
  blue: '#1794c1',
  green: '#006400',
  orange: '#ff6600',
}
